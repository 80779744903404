import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import style from "./index.module.scss"
import Banner from "../../components/Banner"
import homeBackground from "../../images/banner/home_background.png"
import homeContractOverview from "../../images/icon/home_contract_overview.png"
import homeContractManagement from "../../images/icon/home_contract_management.png"
import homeRoleManagement from "../../images/icon/home_role_management.png"
import authorizedInfoManagement from "../../images/icon/authorized_info_management.png"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({
          id: "seo.home.title",
        })}
        lang={intl.formatMessage({
          id: "seo.home.lang",
        })}
        description={intl.formatMessage({
          id: "seo.home.description",
        })}
        keywords={intl.formatMessage({
          id: "seo.home.keywords",
        })}
      />
      <Banner image={homeBackground}>
        <div
          style={{
            overflow: "hidden",
          }}
        >
          <div className={style.textContainer}>
            <div className={style.title}>树图区块链智能合同管理系统</div>
            <div className={style.descContainer}>
              <div className={style.lineContainer}>
                依托树图区块链技术，将传统合同转化为智能合同， 实现
                <span className={style.boldText}>
                  日常业务沟通、各阶段成果提交、合同费用收支、文件资料归档
                </span>
                一体化。
              </div>
              <div className={style.lineContainer}>
                发挥新型基础设施功效，保障各类项目信息的真实、完整、及时，
                <span className={style.boldText}>
                  促进企业之间、政企之间、行业之间
                </span>
                的数据交流与应用。
              </div>
            </div>
          </div>
          <div className={style.cardContainer}>
            <img
              src={homeContractOverview}
              className={classNames(style.cardSmallImg, style.marginOne)}
            />
            <Link to="/contract-create">
              <img
                src={homeContractManagement}
                className={classNames(style.cardBigImg, style.marginTwo)}
              />
            </Link>
            <img
              src={homeRoleManagement}
              className={classNames(style.cardSmallImg, style.marginThree)}
            />
            <img
              src={authorizedInfoManagement}
              className={style.cardSmallImg}
            />
          </div>
        </div>
      </Banner>
    </Layout>
  )
}

export default IndexPage
