import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header"
// import Footer from "./../Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          minWidth
        }
      }
    }
  `)

  return (
    <div
      style={{
        minWidth: data.site.siteMetadata.minWidth,
      }}
    >
      <Header />
      <main>{children}</main>
      {/* <Footer></Footer> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
