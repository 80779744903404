import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const ContentLayout = ({ children, image, color, height, style }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery2 {
      site {
        siteMetadata {
          minWidth
        }
      }
    }
  `)

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: color,
        minWidth: data.site.siteMetadata.minWidth,
        height: height,
        ...style,
      }}
    >
      <div
        style={{
          width: data.site.siteMetadata.minWidth,
          margin: "auto",
        }}
      >
        {children}
      </div>
    </div>
  )
}

ContentLayout.propTypes = {
  image: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
  children: PropTypes.element,
}

ContentLayout.defaultProps = {
  image: "",
  color: "",
  height: "",
  style: {},
  children: undefined,
}

export default ContentLayout
