import React from "react"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import { NAV_ITEMS } from "../../utils/const"
import style from "./index.module.scss"
import logoImg from "../../images/logo.png"
import avatarImg from "../../images/icon/avator.png"

function isCurrentPage(pageName) {
  if (typeof window !== "undefined") {
    let pathname = window.location.pathname.split("/")[1]
    if (pageName === "home" && !pathname) {
      return true
    }
    return pathname === pageName
  }
  return false
}
const Header = () => {
  let intl = useIntl()
  return (
    <header className={style.header}>
      <div className={style.container}>
        <Link to="/">
          <img src={logoImg} className={style.logo} />
        </Link>
        {NAV_ITEMS.map(i => {
          return (
            <span
              key={Math.random()}
              className={classNames(style.navItem, {
                [style.navItemActived]: isCurrentPage(i),
                [style.en]: intl.locale === "en",
              })}
            >
              <Link to={i === "home" ? "/" : `/${i}/`}>
                <FormattedMessage id={`header.${i}`} />
              </Link>
            </span>
          )
        })}
        <div className={style.avatarContainer}>
          <img src={avatarImg} className={style.avatarImg} />
          <span className={classNames(style.navItem, style.init)}>
            上海市建筑学会
          </span>
        </div>
      </div>
    </header>
  )
}

export default Header
