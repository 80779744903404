import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-intl"
import ContentLayout from "../ContentLayout"
import style from "./index.module.scss"

const Banner = ({ children, image, title, description, height }) => {
  // 可以传 title 和 description 作为默认展示内容，也可以自定义展示内容
  let innerHTML = children || (
    <div
      className={style.banner}
      style={{
        height: height,
      }}
    >
      <Link to="/contract-create">
        <h1 className={style.title}>{title}</h1>
      </Link>
      <p className={style.description}>{description}</p>
    </div>
  )
  return <ContentLayout image={image}>{innerHTML}</ContentLayout>
}

Banner.propTypes = {
  image: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  height: PropTypes.string,
  children: PropTypes.element,
}

Banner.defaultProps = {
  image: "",
  title: "",
  description: "",
  height: "570px",
  children: undefined,
}

export default Banner
